var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "img-center",
    attrs: {
      "src": "img/address/no-address.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "no-delivery mt-2"
  }, [_vm._v("Delivery service unavailable")]), _c('p', {
    staticClass: "no-delivery-p-tag mb-0"
  }, [_vm._v("Please choose another one")]), _c('div', {
    staticClass: "mt-4 mb-1 delivery-serv-buttn"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.show('bv-add-address');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v(" Add Address ")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click() {
        return _this.$bvModal.show('bv-modal-examples');
      }
    }
  }, [_c('span', {
    staticClass: "button-text"
  }), _vm._v("Choose Another ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }